<template>
  <EditProductionMenu />
</template>

<script>
import EditProductionMenu from "@/components/productions/editProduction/EditProductionMenu";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
export default {
  name: "EditProduction",
  components: {EditProductionMenu},
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    if (!route.params.id) {
      router.push({name: 'Productions'})
    } else {
      store.dispatch('fetchWorkTypes')
      store.dispatch('fetchProduction', route.params.id)
    }

  },
  async beforeUnmount() {
    await this.$store.dispatch('fetchProduction', this.$store.getters.getProductionId)
  }
}
</script>
