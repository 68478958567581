<template>
  <div class="grid grid-cols-10">
    <div class="w-full grid col-span-10 bg-white py-8">
      <ProfileHeader :profile="profileHeader"/>
    </div>
      <ProfileNavbar class="col-start-3 col-span-6" @change="changeComponent" :navigation="navigation"/>
    <div class="flex justify-end items-end col-start-9 w-full mb-4">
      <button @click="triggerSubmit=true" class=" pr-4 text-sm font-medium rounded-md text-gray-500  dark:bg-primaryDark dark:text-white">Save</button>
      <button @click="exitEdit" class=" pr-4 text-sm font-medium rounded-md text-gray-500  dark:bg-primaryDark dark:text-white">Back</button>
    </div>
    <div class="col-start-3 col-span-7">
      <transition name="fade">
        <component @submitted="save" :submit="triggerSubmit" :is="currentComponent" class="mr-20"/>
      </transition>
    </div>
<!--    <div class="flex justify-end items-end col-start-9 w-full mb-4">-->
<!--      <button @click="exitEdit" class="mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Back</button>-->
<!--    </div>-->
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import ProfileHeader from "@/components/widgets/profileHeader/ProfileHeader";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import ProductionInfo from "@/components/productions/editProduction/ProductionInfo";
import ProductionMembers from "@/components/productions/editProduction/ProductionMembers";
import ProductionMedia from "@/components/productions/editProduction/ProductionMedia";
import ProductionDocuments from "@/components/productions/editProduction/ProductionDocuments";

const navigation = ref([
  { name: 'Info', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionInfo', current: true },
  { name: 'Members', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionMembers', current: false },
  { name: 'Media', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionMedia', current: false },
  { name: 'Documents', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionDocuments', current: false },
])
function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

export default {
  name: "EditProductionMenu",
  components: {ProfileNavbar, ProfileHeader, ProductionInfo, ProductionMembers, ProductionMedia, ProductionDocuments},

  setup(){
    const store = useStore()
    const router = useRouter()
    let currentComponent = ref('ProductionInfo')
    let triggerSubmit = ref(false)

    const profileHeader = reactive({
      logo: computed(() => store.getters.getProductionLogo),
      title: computed(() => store.getters.getProductionName),
      subTitle: "By Thorbjorn Egner",
      location: "Þjóðleikhúsið"
    })

    return {
      profileHeader,
      currentComponent,
      triggerSubmit,
      navigation,
      headerInfo: computed(() => store.getters.getCompanyHeader),
      changeComponent(component) {
        currentComponent.value = component
      },
      exitEdit(){
        router.push({name: "Production", params: { id: store.getters.getProductionId }})
      },
      async save(data){
        await store.dispatch('saveProduction', {
          id:store.getters.getProductionId,
          datePremiered: data.datePremiered,
          description: data.description,
          synopsis: data.synopsis,
          finalPerformance: data.finalPerformance,
          logo: data.logo,
          shortName: data.shortName,
          title: data.title,
          totalAudience: data.totalAudience,
          totalPerformances: data.totalPerformances,
          workType: data.workType,
          runtime: data.runtime
        })

        notify({
          title: "Success",
          text: "Your production has been saved!",
          type: 'success',
          duration: 2000
        })
      }
    }

  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++){
      this.navigation[i].current = false
    }
    this.navigation[0].current = true
  }
}
</script>
